import React, { useEffect } from 'react';
import Post from './Post';

interface Props {
	post: Post;
}

const BasicPostView: React.FC<Props> = (props) => {
	return (
		<div>
		{props.post.imageURLs.map((imageURL, i) => (
			<div className="imageContainer" key={`${props.post.title}-${i}`}>
				<img src={imageURL} 
					style={{
						maxWidth: "80vw", 
						maxHeight: "80vh",
						minHeight: "min(1024px, min(35vh, 80vw))",
						objectFit: "contain"
					}}
					alt={`${props.post.title} ${i + 1}`}
				/>
			</div>
		))}
		</div>
	)
};

export default BasicPostView;