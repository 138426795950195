export default class Post {
	imageURLs: string[];
	title: string;
	redditLink?: string;

	constructor(imageURLs: string[], title: string, redditLink?: string) {
		this.imageURLs = imageURLs;
		this.title = title;
		this.redditLink = redditLink
	}
}
