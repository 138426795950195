import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';

export interface BasicLinkStyleProps extends LinkProps {
	shoulddisable?: number;
}

export const BasicLinkStyle = styled(Link)<BasicLinkStyleProps>`
	background: ${(props) => (props.shoulddisable ? 'gray' : 'black')};
  	pointer-events: ${(props) => (props.shoulddisable ? 'none' : 'auto')};
	margin: min(1vw, 5px);
	border: none;
	color: white;
	padding: 5px;
	border-radius: min(10vw, 10px);
	font-weight: 900;
	width: min(10vw, 30px);
	height: min(10vw, 30px);
	font-size: min(5vw, 15px);
	line-height: min(9vw, 25px);
	text-decoration: none;
`;