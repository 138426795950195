import React from 'react';
import { BasicLinkStyle } from "./BasicStyles";
import { LinkProps } from 'react-router-dom';

export interface BasicLinkProps extends LinkProps {
	shoulddisable?: boolean;
}

export const BasicLink: React.FC<BasicLinkProps> = ({ shoulddisable, ...props }) => (
  <BasicLinkStyle shoulddisable={shoulddisable ? 1 : 0} {...props} />
);