import * as React from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import canvasBackground from "./CanvasBackground.png";

class Canvas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      strokeColor: "black",
      strokeWidth: 8,
      eraserMode: false
    };
    this.canvas = React.createRef();
  }

  downloadImage = () => {
	this.canvas.current
	  .exportImage("png")
	  .then(drawnData => {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		const dimension = 600; // Dimension of the canvas.

		// lets scale the canvas and change its CSS width/height to make it high res.
		canvas.style.width = dimension +'px';
		canvas.style.height = dimension +'px';
		canvas.width = dimension;
		canvas.height = dimension;

		// Now that its high res we need to compensate so our images can be drawn as 
		//normal, by scaling everything up by the pixelRatio.
		// ctx.setTransform(dimension,0,0,dimension,0,0);
  
		const backgroundImg = new Image();
		backgroundImg.src = canvasBackground;
  
		backgroundImg.onload = () => {
		  // Draw the background image first
		  ctx.drawImage(backgroundImg, 0, 0, dimension, dimension);
  
		  const drawnImg = new Image();
		  drawnImg.src = drawnData;
  
		  drawnImg.onload = () => {
			// Draw the drawn image over the background
			ctx.drawImage(drawnImg, 0, 0, dimension, dimension);
  
			// Export the combined image
			const link = document.createElement('a');
			link.href = canvas.toDataURL('image/png');
			link.download = 'GwampDrawing.png';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		  };
		};
	  })
	  .catch(e => {
		console.log(e);
	  });
  }

  handleColorChange = (event) => {
    this.setState({ strokeColor: event.target.value });
  }

  handleWidthChange = (event) => {
    this.setState({ strokeWidth: event.target.value });
  }

  toggleEraserMode = () => {
    this.setState(
      prevState => ({ eraserMode: !prevState.eraserMode }),
      () => {
        this.canvas.current.eraseMode(this.state.eraserMode);
      }
    );
  }

  handleUndo = () => {
    this.canvas.current.undo();
  }

  handleRedo = () => {
    this.canvas.current.redo();
  }

  handleClearDrawing = () => {
    this.canvas.current.clearCanvas();
  }

  render() {
    const { strokeColor, strokeWidth, eraserMode } = this.state;

    return (
      <div style={{ textAlign: 'center' }}>
        <p>El Serpientor's Drawing Pad</p>
        
        <div style={{ display: 'inline-block' }}>
          <label>Color:</label>
          <input type="color" value={strokeColor} onChange={this.handleColorChange} />
        
          <label>Width: {strokeWidth}</label>
          <input type="range" min="1" max="20" value={strokeWidth} onChange={this.handleWidthChange} />
        
          <button onClick={this.toggleEraserMode}>
            {/* <img src={eraserMode ? pencil : pencil} alt="Toggle Mode" /> */}
			{ eraserMode ? "Switch to Pencil" : "Switch to Eraser" }
          </button>
          <button onClick={this.handleUndo}>
			  Undo
            {/* <img src="undo_icon.png" alt="Undo" /> */}
          </button>
          <button onClick={this.handleRedo}>
			  Redo
            {/* <img src="redo_icon.png" alt="Redo" /> */}
          </button>
		  <button onClick={this.handleClearDrawing}>
			  Clear Drawing
            {/* <img src="redo_icon.png" alt="Redo" /> */}
          </button>
        </div>

        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <ReactSketchCanvas
            ref={this.canvas}
			canvasColor={"rgba(255,255,255,0)"}
			width="600"
			height="600"
			backgroundImage={canvasBackground}
            strokeColor={strokeColor}
            strokeWidth={strokeWidth}
            style={{ width: '100%', height: '100%', border: "1px solid black" }} // Set to 100% to fill the parent div
          />
        </div>

        <button onClick={this.downloadImage}>Download Drawing</button>
      </div>
    );
  }
}

export default Canvas;