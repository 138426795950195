import React from 'react';
import './App.css';
import Banner from "./GwampsQuestLogoHorzWhite.svg";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PostsTab from "./PostsTab";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD5Pr_WxeCxanhFjVXQSmvyoSQy4IknORU",
  authDomain: "gwamps-quest.firebaseapp.com",
  databaseURL: "https://gwamps-quest-default-rtdb.firebaseio.com",
  projectId: "gwamps-quest",
  storageBucket: "gwamps-quest.appspot.com",
  messagingSenderId: "308105428348",
  appId: "1:308105428348:web:1b760396923912403438f9",
  measurementId: "G-69DF1MVJSS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

export function UseFirebaseRoutesAnalytics() {
  const location = useLocation();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'screen_view', {
      firebase_screen: location.pathname, // <- In my case I do not want to include search params, so 'location.pathname' is just what I want
      firebase_screen_class: 'firebase-routes-analytics', // <- This name is up to you
    });
  }, [location]);

  return null;
}

function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <div style={{background: "black", marginBottom: "5px"}}>
          <img src={Banner} style={{
            boxSizing: "border-box",
            width: "100%",
            maxHeight: "max(8vh, 40px)",
            padding: "2vh",
            background: "black",
            margin: "0"
          }}
            alt="Gwamp's Quest Banner"
          />
          <p style={{
          color:"white",
          margin: "0",
          }}><em>{"Monday, Wednesday, and Friday at 10AM EST"}</em></p>
        </div>
        <ScrollToTop />
        <UseFirebaseRoutesAnalytics />
        <Routes>
          <Route path="/" element={<PostsTab />} />
          <Route path=":postID" element={<PostsTab />} />
        </Routes>
      </div>
    </BrowserRouter>

  );
}

export default App;