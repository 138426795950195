import React from 'react';
import AllPosts from "./AllPosts";
import BasicPostView from './BasicPostView';
import { useParams } from 'react-router-dom';
import { BasicLink } from "./BasicLink";
import ShopImage from "./ShopPreview.png";
import { isModuleNamespaceObject } from 'util/types';
import Canvas from "./Canvas";


const PostsTab = () => {
	const { postID } = useParams();
	let postIndex = AllPosts.length - 1;
	const postIDIndex = Number(postID);
	if (!!postID && !!postIDIndex && !!AllPosts.at(postIDIndex - 1)) {
		postIndex = postIDIndex - 1;
	}
	const post = AllPosts.at(postIndex);


	const postLinks = () => {
		return <div style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			}}>
				<BasicLink shoulddisable={postIndex <= 0} to="/1">{"<|"}</BasicLink>
				<BasicLink shoulddisable={postIndex <= 0} to={`/${postIndex}`}>{"<"}</BasicLink>
				<h3 style={{ display: "inline" }}>{!!post && post.title ? post.title : `Day ${postIndex + 1}`}</h3>
				<BasicLink shoulddisable={postIndex >= AllPosts.length - 1} to={`/${postIndex + 2}`}>{">"}</BasicLink>
				<BasicLink shoulddisable={postIndex >= AllPosts.length - 1} to={`/${AllPosts.length}`}>{">|"}</BasicLink>
			</div>
	}

	return (
		<div style={{
			paddingBottom: "2vh"
		}}>
			<a href={"https://shop.gwampsquest.com"}>
				<img src={ShopImage} style={{
						maxWidth: "300px", 
						maxHeight: "300px",
						objectFit: "contain"
					}}
					alt={"Gwamp's Shop"} />
				<p>Merch available now!</p>
			</a>
			{ postLinks() }
			<p>(scroll to read)</p>
			{ !!post ? <BasicPostView post={post} /> : <p>Post not found</p>}
			{ postLinks() }
			<Canvas />
		</div >
	)
}


export default PostsTab;