import Post from "./Post";

const AllPosts: Post[] = [
	new Post(["https://i.imgur.com/h9tecUh.webp"], "Day 1"),
	new Post(["https://i.imgur.com/OSw20Co.webp"], "Day 2"),
	new Post(["https://i.imgur.com/60dEQ6G.webp"], "Day 3"),
	new Post(["https://i.imgur.com/8IOE9Mr.webp"], "Day 4"),
	new Post([
		"https://i.imgur.com/l0awgsy.webp",
		"https://i.imgur.com/VLBokVI.webp",
		"https://i.imgur.com/PIyOPMG.webp",
		"https://i.imgur.com/PJasqiw.webp",
		"https://i.imgur.com/KbOoFes.webp",
	], "Day 5"),
	new Post([
		"https://i.imgur.com/xzMWydN.webp",
		"https://i.imgur.com/rgdFYmq.webp",
		"https://i.imgur.com/0gh7yYe.webp",
	], "Day 6"),
	new Post([
		"https://i.imgur.com/XoUYI2U.webp",
		"https://i.imgur.com/aqgiU2N.webp",
		"https://i.imgur.com/sKAmtFc.webp",
	], "Day 7"),
	new Post([
		"https://i.imgur.com/YAEQ4bZ.webp",
		"https://i.imgur.com/dfIyUzm.webp",
		"https://i.imgur.com/NGmD5dJ.webp",
		"https://i.imgur.com/TYRRCqI.webp",
		"https://i.imgur.com/Aa38Mir.webp",
		"https://i.imgur.com/Jg1suIm.webp",
		"https://i.imgur.com/nvTyNje.webp",
	], "Day 8"),
	new Post([
		"https://i.imgur.com/R0rnZXg.webp",
		"https://i.imgur.com/IF55ctX.webp",
		"https://i.imgur.com/6EKEOwk.webp",
	], "Day 9"),
	new Post([
		"https://i.imgur.com/HTebAQd.webp",
		"https://i.imgur.com/QYSoQtZ.webp",
		"https://i.imgur.com/LpmUrAO.webp",
	], "Day 10"),
	new Post([
		"https://i.imgur.com/X3yJHry.webp",
		"https://i.imgur.com/kHaZsuP.webp",
		"https://i.imgur.com/TgRjB2p.webp",
	], "Day 11"),
	new Post([
		"https://i.imgur.com/vmdgMhY.webp",
		"https://i.imgur.com/7xi0uSr.webp",
		"https://i.imgur.com/jtDe5CL.webp",
	], "Day 12"),
	new Post([
		"https://i.imgur.com/ULE0nrL.webp",
		"https://i.imgur.com/A8PypFJ.webp",
		"https://i.imgur.com/bRlPBQw.webp",
		"https://i.imgur.com/AVfMsMQ.webp",
		"https://i.imgur.com/MHmwsuG.webp",
		"https://i.imgur.com/Z77Pdgd.webp",
	], "Day 13"),
	new Post([
		"https://i.imgur.com/FlMjyGl.webp",
		"https://i.imgur.com/nS2M8pG.webp",
		"https://i.imgur.com/oCm57XR.webp",
		"https://i.imgur.com/bwE6W1W.webp",
	], "Day 14"),
	new Post([
		"https://i.imgur.com/H0aMBor.webp",
		"https://i.imgur.com/53U052q.webp",
		"https://i.imgur.com/ywpYNx0.webp",
		"https://i.imgur.com/hLz7Lzu.webp",
		"https://i.imgur.com/ZoKeTOP.webp",
		"https://i.imgur.com/EE1rTTI.webp",
	], "Day 15"),
	new Post([
		"https://i.imgur.com/xhLh7Ig.webp",
		"https://i.imgur.com/O64D62n.webp",
	], "Day 16"),
	new Post([
		"https://i.imgur.com/bM3ZbwR.webp",
		"https://i.imgur.com/bZns9ie.webp",
		"https://i.imgur.com/VoHsJoc.webp",
		"https://i.imgur.com/JeZPdKr.webp",
		"https://i.imgur.com/slj6OjO.webp",
		"https://i.imgur.com/eTYXBm5.webp",
	], "Day 17"),
	new Post([
		"https://i.imgur.com/IyZe6VS.webp",
		"https://i.imgur.com/dvfZf1q.webp",
		"https://i.imgur.com/Xy6xc3Z.webp",
		"https://i.imgur.com/9Wr07q4.webp",
		"https://i.imgur.com/Zugl4Za.webp",
	], "Day 18"), 
	new Post([
		"https://i.imgur.com/t1T6wi4.webp",
		"https://i.imgur.com/SUxdJyB.webp",
		"https://i.imgur.com/ka2kYqh.webp",
	], "Day 19"),
	new Post([
		"https://i.imgur.com/JvKVXpM.webp",
		"https://i.imgur.com/0EjpPYe.webp",
		"https://i.imgur.com/mF3nQ08.webp",
		"https://i.imgur.com/fNXmsEK.webp",
	], "Day 20"),
	new Post([
		"https://i.imgur.com/rIUacKr.webp",
		"https://i.imgur.com/JecRWAQ.webp",
		"https://i.imgur.com/63ZGVu8.webp",
		"https://i.imgur.com/Eeh5Ccv.webp",
	], "Day 21"),
	new Post([
		"https://i.imgur.com/WG3JSDI.webp",
		"https://i.imgur.com/3Th0M8y.webp",
		"https://i.imgur.com/HDQRwF0.webp",
		"https://i.imgur.com/uLMserQ.webp",
		"https://i.imgur.com/Irv9mWg.webp",
		"https://i.imgur.com/yFYfCL3.webp",
		"https://i.imgur.com/l4FHwSE.webp",
		"https://i.imgur.com/4SoYwy2.webp",
		"https://i.imgur.com/eDfIEns.webp",
	], "Day 22"),
	new Post([
		"https://i.imgur.com/JMjbKJA.webp",
		"https://i.imgur.com/pIDGbii.webp",
	], "Day 23"),
	new Post([
		"https://i.imgur.com/FwLlN6Y.webp",
		"https://i.imgur.com/uS534Xs.webp",
		"https://i.imgur.com/L19g7dx.webp",
	], "Day 24"),
	new Post([
		"https://i.imgur.com/TLprsRt.webp",
		"https://i.imgur.com/UWxKBhi.webp",
		"https://i.imgur.com/gZuXSoz.webp",
		"https://i.imgur.com/qobtpse.webp",
	], "Day 25"),
	new Post([
		"https://i.imgur.com/zLlmsd9.webp",
		"https://i.imgur.com/fnLQ3Tg.webp",
		"https://i.imgur.com/dSMGwfg.webp",
		"https://i.imgur.com/nkddAcx.webp",
	], "Day 26"),
	new Post([
		"https://i.imgur.com/7aftNWH.webp",
	], "Day 27"),
	new Post([
		"https://i.imgur.com/QHpctgy.webp",
		"https://i.imgur.com/oX8KGnv.webp",
		"https://i.imgur.com/cIK6U2q.webp",
	], "Day 28"),
	new Post([
		"https://i.imgur.com/LJKpj9q.webp",
		"https://i.imgur.com/CftHkKw.webp",
		"https://i.imgur.com/ryEsrEn.webp",
	], "Day 29"),
	new Post([
		"https://i.imgur.com/0K8AbdY.webp",
		"https://i.imgur.com/vzP1avg.webp",
		"https://i.imgur.com/FzkK4F0.webp",
		"https://i.imgur.com/Mk9Hr1S.webp",
	], "Day 30"),
	new Post([
		"https://i.imgur.com/v6NyRpk.webp",
		"https://i.imgur.com/847sIo5.webp",
		"https://i.imgur.com/92RsSbS.webp",
		"https://i.imgur.com/bYMNRRW.webp",
	], "Day 31"),
	new Post([
		"https://imgur.com/fIM2NHo.png",
		"https://imgur.com/UgtfyLp.png",
		"https://imgur.com/YpxIQZH.png",
		"https://imgur.com/1T9KMFJ.png",
	], "Day 32"),
	new Post([
		"https://imgur.com/QKHhIc8.png",
		"https://imgur.com/I1it2zL.png",
		"https://imgur.com/ZobLH2z.png",
		"https://imgur.com/Ti1CIow.png",
		"https://imgur.com/IJROeP2.png",
		"https://imgur.com/chkT1De.png",
		"https://imgur.com/5572wOb.png",
	], "Day 33"),
	new Post([
		"https://imgur.com/02LuQyU.png",
		"https://imgur.com/upGvG6k.png",
		"https://imgur.com/8gnihAy.png",
		"https://imgur.com/6axXwVF.png",
	], "Day 34"),
	new Post([
		"https://imgur.com/79kzieA.png",
		"https://imgur.com/sSxCPVR.png",
		"https://imgur.com/jKcK9rR.png",
		"https://imgur.com/fq9ZcY9.png",
		"https://imgur.com/RMfvMnl.png",
		"https://imgur.com/JfDi2nF.png",
		"https://imgur.com/GjpCej8.png",
		"https://imgur.com/i1sxPn3.png",
		"https://imgur.com/QiHlqJP.png",
	], "Day 35"),
	new Post([
		"https://imgur.com/drvmWkf.png",
		"https://imgur.com/O80d2te.png",
		"https://imgur.com/E7efRK4.png",
	], "Day 36"),
	new Post([
		"https://imgur.com/ICkV20S.png",
		"https://imgur.com/RSk1meX.png",
		"https://imgur.com/UikCLPP.png",
		"https://imgur.com/djbwXTV.png",
		"https://imgur.com/WpoqSCn.png",
		"https://imgur.com/QkPF8SJ.png",
		"https://imgur.com/wI3uBsk.png",
	], "Day 37"),
	new Post([
		"https://imgur.com/6XEWhQx.png",
		"https://imgur.com/x2uctjh.png",
		"https://imgur.com/lYfw1g3.png",
	], "Day 38"),
	new Post([
		"https://imgur.com/uj4c4Oh.png",
		"https://imgur.com/wEcPa5s.png",
		"https://imgur.com/zif9nNF.png",
	], "Day 39"),
	new Post([
		"https://imgur.com/H9b6epE.png",
		"https://imgur.com/b7Ne5VO.png",
		"https://imgur.com/8kDjZsu.png",
		"https://imgur.com/J5afNva.png",
		"https://imgur.com/sT0wSpF.png",
	], "Day 40"),
	new Post([
		"https://imgur.com/k523cFT.png",
	], "Day 40.5"),
	new Post([
		"https://imgur.com/AsrP2T1.jpeg",
		"https://imgur.com/1aWhDaN.jpeg",
		"https://imgur.com/thqPJPK.jpeg",
		"https://imgur.com/OfiXUU6.jpeg",
		"https://imgur.com/r7nZq6X.jpeg",
		"https://imgur.com/2y6T0mZ.jpeg",
	], "Day 41"),
	new Post([
		"https://imgur.com/D6C4p8O.png",
		"https://imgur.com/6Uolasn.png",
		"https://imgur.com/BEpGCiM.png",
	], "Day 42"),
	new Post([
		"https://imgur.com/hrceKtQ.png",
		"https://imgur.com/PphwrzZ.png",
	], "Day 43"),
	new Post([
		"https://imgur.com/dbNNagF.png",
		"https://imgur.com/D7u16Ki.png",
		"https://imgur.com/k1k5yCF.png",
		"https://imgur.com/miLvJEx.png",
		"https://imgur.com/1FrLwGB.png",
	], "Day 44"),
	new Post([
		"https://imgur.com/ONkBtNd.png",
		"https://imgur.com/D61DSPB.png",
		"https://imgur.com/ky5KHTW.png",
		"https://imgur.com/jhuNllg.png",
		"https://imgur.com/NhSjJQk.png",
	], "Day 45"),
	new Post([
		"https://imgur.com/SaCUYJg.png",
		"https://imgur.com/sm3HtCV.png",
		"https://imgur.com/mU6uSfS.png",
		"https://imgur.com/AAPz1CA.png",
		"https://imgur.com/fT2sBrN.png",
	], "Day 46"),
	new Post([
		"https://imgur.com/EmuXMYi.png",
		"https://imgur.com/RYRzEg6.png",
		"https://imgur.com/afSIEM1.png",
	], "Day 47"),
];


export default AllPosts;